import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const MenuArrow = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={8}
      height={16}
      viewBox="0 0 8 16"
      fill={color}
      ref={ref}
      {...otherProps}
    >
      <path d="M8 8L0.5 15.3612L0.500001 0.638784L8 8Z" fill={color} />
    </svg>
  );
});

MenuArrow.defaultProps = {
  color: 'currentColor',
};

MenuArrow.displayName = 'MenuArrow';

export default MenuArrow;
