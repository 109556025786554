import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Dropdown = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path d="M15 11l-2.997 3L9 11" stroke={color} strokeMiterlimit={10} />
      <circle cx={12} cy={12} r={8.5} stroke={color} />
    </svg>
  );
});

Dropdown.defaultProps = {
  color: 'currentColor',
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
