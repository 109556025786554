import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Incomplete = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M4.292 18.45L12 5.1l7.708 13.35H4.292z"
        stroke={color}
        strokeWidth={1.1}
      />
      <path
        d="M11.5 16a.5.5 0 0 0 1 0h-1zm1-5a.5.5 0 0 0-1 0h1zm0 5v-5h-1v5h1z"
        fill={color}
      />
    </svg>
  );
});

Incomplete.defaultProps = {
  color: 'currentColor',
};

Incomplete.displayName = 'Incomplete';

export default Incomplete;
