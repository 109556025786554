import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Company = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path stroke={color} strokeLinecap="round" d="M8.5 6.5h7M8.5 8.5h7M8.5 10.5h7" />
      <path stroke={color} d="M9.5 20.5v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5" />
      <rect width="13" height="17" x="5.5" y="3.5" stroke={color} rx="1.5" />
    </svg>
  );
});

Company.defaultProps = {
  color: 'currentColor',
};

Company.displayName = 'Company';

export default Company;
