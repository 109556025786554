import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & { disabled?: boolean };

const Form = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { disabled, ...otherProps } = props;

  return (
    <svg
      width={49}
      height={49}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M11.3926 11.375C11.3926 9.71815 12.7357 8.375 14.3926 8.375H34.3926C36.0494 8.375 37.3926 9.71815 37.3926 11.375V32.875C34.3926 34.375 31.5494 40.375 29.8926 40.375H14.3926C12.7357 40.375 11.3926 39.0319 11.3926 37.375V11.375Z"
        fill={disabled ? '#8F9194' : '#4D65FF'}
      />
      <path
        d="M29.3926 32.375L37.3926 32.375L37.3926 32.5475C37.3926 33.0389 37.2959 33.5243 37.1094 33.9693C36.923 34.4142 36.6513 34.8078 36.3136 35.122L31.5011 39.6014C30.9634 40.1015 30.2863 40.3752 29.5871 40.375L29.3926 40.375L29.3926 32.375Z"
        fill={disabled ? '#CECFD0' : '#AAB5FF'}
      />
      <rect
        x={18.3926}
        y={7.375}
        width={12}
        height={4}
        rx={2}
        fill={disabled ? '#CECFD0' : '#AAB5FF'}
      />
      <path
        d="M20.3926 17.586H31.3926"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M20.3926 22.586H31.3926"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle
        cx={17.3926}
        cy={17.586}
        r={1}
        fill="white"
      />
      <circle
        cx={17.3926}
        cy={22.586}
        r={1}
        fill="white"
      />
    </svg>
  );
});

Form.defaultProps = {
  disabled: false,
};

Form.displayName = 'Form';

export default Form;
