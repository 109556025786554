import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const DownloadSheet = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M24.452 17.111V7.333H12.491a3.318 3.318 0 00-3.325 3.325v22.684a3.318 3.318 0 003.325 3.325h17.355a3.318 3.318 0 003.325-3.325V17.111h-8.719z"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.491 7.333h2.868l6.796 8.116v1.662"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.333 31.333H15.667"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M24.665 25.83l-3.495 3.503-3.504-3.503M21.17 29.333v-8.666"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

DownloadSheet.defaultProps = {
  color: 'currentColor',
};

DownloadSheet.displayName = 'DownloadSheet';

export default DownloadSheet;
