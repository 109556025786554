import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Upload = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        d="M20 20H4"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
        d="M7 9.255 12.242 4l5.255 5.255M12.243 4v12"
      />
    </svg>
  );
});

Upload.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Upload.displayName = 'Upload';

export default Upload;
