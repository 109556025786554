import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const SolidCircleX = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M12 20a8 8 0 100-16 8 8 0 000 16z"
        fill={color}
        stroke={color}
        strokeMiterlimit={10}
      />
      <path
        d="M9.354 8.646a.5.5 0 10-.708.708l.708-.708zm5.292 6.708a.5.5 0 00.708-.708l-.708.708zm.708-6a.5.5 0 00-.708-.708l.708.708zm-6.708 5.292a.5.5 0 00.708.708l-.708-.708zm0-5.292l6 6 .708-.708-6-6-.708.708zm6-.708l-6 6 .708.708 6-6-.708-.708z"
        fill="#fff"
      />
    </svg>
  );
});

SolidCircleX.defaultProps = {
  color: 'currentColor',
};

SolidCircleX.displayName = 'SolidCircleX';

export default SolidCircleX;
