import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Checklist = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect width={7} height={1} x={13} y={5} fill={color} rx={0.5} strokeWidth={strokeWidth} />
      <rect width={7} height={1} x={13} y={9} fill={color} rx={0.5} strokeWidth={strokeWidth} />
      <rect width={16} height={1} x={4} y={13} fill={color} rx={0.5} strokeWidth={strokeWidth} />
      <rect width={16} height={1} x={4} y={17} fill={color} rx={0.5} strokeWidth={strokeWidth} />
      <path
        d="M5 8.016 7.055 10 11 6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Checklist.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Checklist.displayName = 'Checklist';

export default Checklist;
