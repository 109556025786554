import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const BulkAdd = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect x={8} y={4} width={12} height={12} rx={2} stroke={color} />
      <path
        d="M6 8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2"
        stroke={color}
      />
      <path
        d="M8 6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2"
        stroke={color}
      />
    </svg>
  );
});

BulkAdd.defaultProps = {
  color: 'currentColor',
};

BulkAdd.displayName = 'BulkAdd';

export default BulkAdd;
