import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const OrderedList = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={25}
      ref={ref}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fill={color}
        d="M7.317 5.592v3.554h-.823V6.52l-.81.236v-.625l1.557-.54h.076Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        d="M10 7.37h9"
      />
      <path
        fill={color}
        d="M7.777 13.118v.634h-2.48v-.537l1.142-1.2c.1-.116.182-.22.244-.31.062-.094.107-.178.134-.252a.563.563 0 0 0 .044-.203.622.622 0 0 0-.046-.256.328.328 0 0 0-.132-.159.392.392 0 0 0-.212-.054.354.354 0 0 0-.222.073.484.484 0 0 0-.15.2.757.757 0 0 0-.05.286h-.826a1.162 1.162 0 0 1 .606-1.03c.19-.109.41-.164.661-.164.262 0 .482.041.66.123.177.08.31.199.4.354.091.153.137.337.137.554 0 .123-.02.242-.06.356-.038.114-.094.227-.168.34-.073.11-.162.224-.268.341a8.029 8.029 0 0 1-.357.374l-.459.53h1.402Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        d="M10 11.95h9"
      />
      <path
        fill={color}
        d="M6.06 16.236h.4a.519.519 0 0 0 .252-.053.354.354 0 0 0 .15-.157.557.557 0 0 0 .048-.241.427.427 0 0 0-.046-.198.333.333 0 0 0-.137-.144.45.45 0 0 0-.237-.056.463.463 0 0 0-.344.153.277.277 0 0 0-.059.179h-.825c0-.202.056-.375.166-.518a1.07 1.07 0 0 1 .442-.332c.184-.078.382-.117.593-.117.253 0 .474.039.664.117.19.077.339.19.445.342a.93.93 0 0 1 .16.557.724.724 0 0 1-.085.344.897.897 0 0 1-.239.283 1.197 1.197 0 0 1-.361.193 1.412 1.412 0 0 1-.45.068h-.536v-.42Zm0 .613v-.41h.538c.177 0 .339.02.486.061.146.04.272.098.378.176a.792.792 0 0 1 .244.29.868.868 0 0 1 .085.393.96.96 0 0 1-.1.445.941.941 0 0 1-.28.33c-.12.089-.262.157-.423.204a1.922 1.922 0 0 1-.969.01 1.35 1.35 0 0 1-.405-.185.983.983 0 0 1-.296-.323.956.956 0 0 1-.11-.47h.826c0 .074.02.142.06.204a.491.491 0 0 0 .396.198c.101 0 .187-.019.257-.056a.41.41 0 0 0 .22-.374c0-.12-.021-.216-.062-.288a.346.346 0 0 0-.173-.156.679.679 0 0 0-.271-.049h-.4Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        d="M10 16.58h9"
      />
    </svg>
  );
});

OrderedList.defaultProps = {
  color: 'currentColor',
};

OrderedList.displayName = 'OrderedList';

export default OrderedList;
