import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Multiselect = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...otherProps}
    >
      <path
        clipRule="evenodd"
        d="M15.856 7.851a.5.5 0 00-.712-.702l-5.054 5.124-2.126-2.127a.5.5 0 00-.707.708l2.131 2.131-1.786 1.812-3.755-3.625a.5.5 0 00-.694.72l4.11 3.968a.5.5 0 00.703-.009l2.13-2.159 2.16 2.162a.5.5 0 00.71-.003l7.89-8a.5.5 0 00-.712-.702l-7.536 7.641-1.81-1.81 5.058-5.129z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

Multiselect.defaultProps = {
  color: 'currentColor',
};

Multiselect.displayName = 'Multiselect';

export default Multiselect;
