import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Column = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M6.001 4.5v11M9.999 4.5v6M13.999 4.5v15M17.999 4.5v8"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Column.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Column.displayName = 'Column';

export default Column;
