import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const UserPlus = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <g opacity={0.4}>
        <circle cx={12} cy={12} r={11.5} stroke={color} strokeDasharray="2 2" />
        <path
          d="M14.322 7.556a3.064 3.064 0 01-3.072 3.055A3.063 3.063 0 018.18 7.556 3.064 3.064 0 0111.25 4.5a3.064 3.064 0 013.072 3.056z"
          stroke={color}
        />
        <path
          d="M11.25 11.111c-3.452 0-6.25 2.786-6.25 6.222h12.5c0-3.436-2.798-6.222-6.25-6.222z"
          stroke={color}
          strokeLinecap="round"
        />
        <path
          d="M19 7a.5.5 0 00-1 0h1zm-1 5a.5.5 0 001 0h-1zm-2-3a.5.5 0 000 1V9zm5 1a.5.5 0 000-1v1zm-3-3v5h1V7h-1zm-2 3h5V9h-5v1z"
          fill={color}
        />
      </g>
    </svg>
  );
});

UserPlus.defaultProps = {
  color: 'currentColor',
};

UserPlus.displayName = 'UserPlus';

export default UserPlus;
