import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Drag = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle
        cx={9.5}
        cy={6.5}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={9.5}
        cy={12.5}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={9.5}
        cy={18.5}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={14.5}
        cy={6.5}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={14.5}
        cy={12.5}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={14.5}
        cy={18.5}
        fill={color}
        r={1.5}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Drag.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Drag.displayName = 'Drag';

export default Drag;
