import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Star = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M12 4.118l1.77 5.446a.5.5 0 00.475.346h5.726l-4.632 3.366a.5.5 0 00-.182.559l1.77 5.446-4.633-3.366a.5.5 0 00-.588 0l-4.633 3.366 1.77-5.446a.5.5 0 00-.182-.56L4.028 9.91h5.727a.5.5 0 00.475-.346L12 4.118z"
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  );
});

Star.defaultProps = {
  color: 'currentColor',
};

Star.displayName = 'Star';

export default Star;
