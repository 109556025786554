import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Zoom = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle
        cx={10}
        cy={10}
        r={6}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="m20.026 20.065-5.748-5.748M9.967 7.498v5.091M7.482 9.982h5.051"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Zoom.defaultProps = {
  color: 'currentColor',
};

Zoom.displayName = 'Zoom';

export default Zoom;
