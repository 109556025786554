import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Number = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={25}
      ref={ref}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        clipRule="evenodd"
        d="M8 5.049h8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3Zm-4 3a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4v-8Zm7.204 5.638h1.038l-.387 2.112h.984l.387-2.112h1.365V12.8h-1.204l.269-1.463H15v-.896h-1.177l.392-2.143h-.984l-.392 2.143h-1.043l.392-2.143h-.978l-.393 2.143H9.403v.896h1.247l-.268 1.463H9v.886h1.22l-.387 2.112h.984l.387-2.112Zm1.2-.886h-1.038l.263-1.463h1.043l-.269 1.463Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

Number.defaultProps = {
  color: 'currentColor',
};

Number.displayName = 'Number';

export default Number;
