import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  direction?: 'up' | 'down' | 'left' | 'right'
  strokeWidth?: number
};

const rotationMap = {
  up: -90,
  down: 90,
  left: 180,
  right: 0,
};

const Arrow = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color, direction, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      style={{
        transformOrigin: 'center',
        transition: 'transform 0.25s',
        transform: `rotate(${rotationMap[direction]}deg)`,
      }}
      ref={ref}
      {...otherProps}
    >
      <path
        d="M14.745 7 20 12.243l-5.255 5.254M20 12.243H4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Arrow.defaultProps = {
  color: 'currentColor',
  direction: 'right',
  strokeWidth: 1,
};

Arrow.displayName = 'Arrow';

export default Arrow;
