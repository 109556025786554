import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Add = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M12 6v12m-6-6h12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Add.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Add.displayName = 'Add';

export default Add;
