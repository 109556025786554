import { forwardRef } from 'react';

type Props = { color?: string, strokeWidth?: number };

const CalendarEdit = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...otherProps}
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      >
        <path d="M7.76 19.5H5.972a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v.29M3.984 9.05H15.46" />
        <path
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="m11.498 17.569-.696 2.259-.059.212c-.087.3-.183.877.088 1.148.271.27.843.182 1.15.087l.213-.058 2.293-.695 5.948-5.936-2.982-2.982-5.955 5.965ZM22.347 12.298a.933.933 0 0 0-.27-.665l-1.693-1.689a.937.937 0 0 0-.667-.27.807.807 0 0 0-.621.286l-1.59 1.594 2.973 2.967 1.597-1.594c.183-.146.271-.387.271-.629Z"
        />
        <path d="M7.5 3v3M16.5 3v3" />
      </g>
    </svg>
  );
});

CalendarEdit.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

CalendarEdit.displayName = 'CalendarEdit';

export default CalendarEdit;
