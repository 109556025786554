import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Stop = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle
        cx={12}
        cy={12}
        r={11.5}
        stroke={color}
      />
      <path
        d="M7.286 16.674l8.721-8.721"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
});

Stop.defaultProps = {
  color: 'currentColor',
};

Stop.displayName = 'Stop';

export default Stop;
