import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Refresh = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      ref={ref}
      fill="none"
      {...otherProps}
    >
      <path
        d="M7.023 9.14a1 1 0 0 0-1.731-1l1.731 1ZM12 17.754a5.743 5.743 0 0 1-5.743-5.744h-2A7.743 7.743 0 0 0 12 19.753v-2Zm-5.743-5.744c0-1.046.279-2.025.766-2.868l-1.731-1a7.712 7.712 0 0 0-1.035 3.868h2Z"
        fill={color}
      />
      <path
        d="m10.249 16.023 2.499 3.011-3.399 1.908"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M16.972 14.886a1 1 0 1 0 1.73 1.003l-1.73-1.003ZM12 6.266a5.743 5.743 0 0 1 5.743 5.743h2A7.743 7.743 0 0 0 12 4.266v2Zm5.743 5.743c0 1.05-.28 2.032-.77 2.877l1.73 1.003a7.712 7.712 0 0 0 1.04-3.88h-2Z"
        fill={color}
      />
      <path
        d="m13.671 8.014-2.44-3.058 3.435-1.842"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Refresh.defaultProps = {
  color: 'currentColor',
};

Refresh.displayName = 'Refresh';

export default Refresh;
