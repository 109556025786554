import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & { disabled?: boolean };

const AttachmentLink = forwardRef<SVGSVGElement, Props>(({ disabled, ...props }, ref) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M26.616 18.654V7.962h.206a4 4 0 013.075 1.442l5.35 6.432a4 4 0 01.924 2.558v.26h-9.555z"
      fill={disabled ? '#CECFD0' : '#AAB5FF'}
    />
    <path
      d="M30.669 18.654a4 4 0 01-4-4V7.962H13.633a3.622 3.622 0 00-3.623 3.635v24.806a3.622 3.622 0 003.623 3.635h18.915a3.622 3.622 0 003.623-3.635V18.654h-5.502z"
      fill={disabled ? '#8F9194' : '#4D65FF'}
    />
    <path
      d="M24.016 30.378l-2.252 2.254c-.94.94-2.453.94-3.392 0a2.395 2.395 0 010-3.394l2.245-2.246M21.99 25.618l2.246-2.246c.94-.94 2.453-.94 3.393 0s.94 2.453 0 3.393l-2.246 2.247M24.79 26.178l-3.626 3.627"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </svg>
));

AttachmentLink.defaultProps = {
  disabled: false,
};

AttachmentLink.displayName = 'AttachmentLink';

export default AttachmentLink;
