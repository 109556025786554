import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Calendar = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect
        height={15}
        rx={2}
        stroke={color}
        strokeWidth={strokeWidth}
        width={16}
        x={4}
        y={5}
      />
      <path
        d="M4 9h16"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M7.5 3.5v3M16.5 3.5v3"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Calendar.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Calendar.displayName = 'Calendar';

export default Calendar;
