import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Link = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="m13.524 15.568-3.378 3.38a3.59 3.59 0 0 1-5.089 0 3.592 3.592 0 0 1 0-5.09l3.37-3.37M10.485 8.428l3.37-3.37a3.59 3.59 0 0 1 5.088 0 3.592 3.592 0 0 1 0 5.09l-3.37 3.37M14.684 9.268l-5.438 5.44"
        stroke={color}
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Link.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Link.displayName = 'Link';

export default Link;
