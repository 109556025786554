import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type IconClasses = {
  circle?: string;
  check?: string;
};

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  disabled?: boolean
  classes?: undefined | IconClasses
};

const disabledColor = '#D5D5D5';

const InProgress = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { color, classes, disabled, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      data-cy="in-progress"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M12 20a8 8 0 100-16"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        className={(classes && classes.circle) && classes.circle}
        fill={disabled ? disabledColor : 'transparent'}
      />
      <path
        d="M12 20a8 8 0 110-16"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeDasharray="2 3"
        className={(classes && classes.circle) && classes.circle}
        fill={disabled ? disabledColor : 'transparent'}
      />
      <path
        d="M8.16 10.809l3.813 3.822L20 6.321"
        stroke="transparent"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={(classes && classes.check) && classes.check}
      />
    </svg>
  );
});

InProgress.defaultProps = {
  disabled: false,
  color: 'currentColor',
  classes: undefined,
};

InProgress.displayName = 'InProgress';

export default InProgress;
