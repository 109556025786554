import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Dependency = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M6 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM17 19.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM17 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        stroke={color}
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
      <path
        d="M14 7H9"
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
      <path
        d="M11 7v8a2 2 0 0 0 2 2h2"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Dependency.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Dependency.displayName = 'Dependency';

export default Dependency;
