import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const List = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        d="M6 6.043h12M6 9.957h12M6 13.957h12M6 17.957h12"
      />
    </svg>
  );
});

List.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

List.displayName = 'List';

export default List;
