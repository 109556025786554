import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Formula = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg width={24} height={24} viewBox="0 0 24 25" fill="none" ref={ref} {...otherProps}>
      <path
        d="M3.99475 16.1512L6.03624 18.601L9.95589 5.24966H20"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M13.3019 10.3309L14.6906 12.6395L16.0968 10.3309H17.3683L15.2941 13.4598L17.4327 16.6708H16.1788L14.714 14.2919L13.2491 16.6708H11.9894L14.1222 13.4598L12.0538 10.3309H13.3019Z"
        fill={color}
      />
    </svg>
  );
});

Formula.defaultProps = {
  color: 'currentColor',
};

Formula.displayName = 'Formula';

export default Formula;
