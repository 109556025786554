import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const SolidCheckbox = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
        fill={color}
        stroke={color}
        strokeMiterlimit={10}
      />
      <path
        d="M7 12.791l3.22 3.229L17 9"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

SolidCheckbox.defaultProps = {
  color: 'currentColor',
};

SolidCheckbox.displayName = 'SolidCheckbox';

export default SolidCheckbox;
