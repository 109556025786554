import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Dashboard = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle
        cx={12}
        cy={12.5}
        r={8.5}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M17 12.5a5 5 0 0 0-10 0"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={12}
        cy={15.5}
        r={1.5}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="m13.098 13.799 1.5-2.598"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Dashboard.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Dashboard.displayName = 'Dashboard';

export default Dashboard;
