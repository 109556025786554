import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Email = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill={color}
      ref={ref}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.041 7H5.96L12 11.11 18.041 7zM5 7.557v8.16l4.476-5.115L5 7.557zm.706 8.872h12.588l-4.603-5.26L12 12.319l-1.691-1.15-4.603 5.26zM19 15.717v-8.16l-4.476 3.045L19 15.717zM4 7a1 1 0 011-1h14a1 1 0 011 1v9.429a1 1 0 01-1 1H5a1 1 0 01-1-1V7z"
        fill={color}
      />
    </svg>
  );
});

Email.defaultProps = {
  color: 'currentColor',
};

Email.displayName = 'Email';

export default Email;
