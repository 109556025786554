import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Details = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M29 19H16"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M29 24H16"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M29 29H16"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M29 8H31C32.6569 8 34 9.34315 34 11V35C34 36.6569 32.6569 38 31 38H13C11.3431 38 10 36.6569 10 35V11C10 9.34315 11.3431 8 13 8H15"
        stroke={color}
      />
      <rect
        height={5}
        rx={2.5}
        stroke={color}
        width={14}
        x={15}
        y={6}
      />
    </svg>
  );
});

Details.defaultProps = {
  color: 'currentColor',
};

Details.displayName = 'Details';

export default Details;
