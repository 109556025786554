import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Image = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={24}
      ref={ref}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <rect
        width={11.078}
        height={8.857}
        x={6.461}
        y={7.57}
        stroke={color}
        rx={0.5}
      />
      <circle cx={9.632} cy={10.477} r={1.024} stroke={color} />
      <path
        stroke={color}
        strokeLinecap="round"
        d="m6.292 14.609.05-.062m0 0 .694-.841a1 1 0 0 1 1.297-.216l1.13.698a1 1 0 0 0 1.212-.124l2.737-2.584a1 1 0 0 1 1.424.05l2.61 2.846a1 1 0 0 1 .264.667l.005.544a1 1 0 0 1-1.006 1.009l-9.373-.05a1 1 0 0 1-.995-1.002l.002-.997Z"
      />
    </svg>
  );
});

Image.defaultProps = {
  color: 'currentColor',
};

Image.displayName = 'Image';

export default Image;
