import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Chat = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M20 5H4v11.14h7.83L16.86 20l.05-3.86H20V5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Chat.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Chat.displayName = 'Chat';

export default Chat;
