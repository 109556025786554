import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Duplicate = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect
        width={10}
        height={14}
        x={9}
        y={7}
        rx={2}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M9 17H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Duplicate.defaultProps = {
  color: 'currentColor',
  strokeWidth: 1,
};

Duplicate.displayName = 'Duplicate';

export default Duplicate;
