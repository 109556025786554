import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const OpenDrawer = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="m14.371 15.428-3.433-3.424 3.433-3.433M10.938 12.004h8.492"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 7.429V6a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1.429"
        stroke={color}
      />
    </svg>
  );
});

OpenDrawer.defaultProps = {
  color: 'currentColor',
};

OpenDrawer.displayName = 'OpenDrawer';

export default OpenDrawer;
