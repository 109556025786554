import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Phone = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      fill="none"
      height={25}
      ref={ref}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        stroke={color}
        d="M3.503 6.751c-.04-.839.337-2.239.924-3.225.297-.497.599-.807.851-.925a.443.443 0 0 1 .285-.044c.083.017.203.07.352.223L8.8 5.705c.223.226.224.632-.039.898l-.606.614a1.483 1.483 0 0 0-.239 1.777c.846 1.483 1.647 2.623 2.615 3.61.97.988 2.09 1.805 3.559 2.66.59.344 1.33.23 1.8-.247l.593-.602-.356-.351.356.351c.26-.264.649-.26.865-.041l2.882 2.925c.177.18.243.326.263.435a.525.525 0 0 1-.064.348c-.149.286-.532.616-1.148.898-1.219.558-3.018.78-4.43.322-2.207-.714-5.002-2.64-7.281-5.034-2.29-2.405-3.953-5.17-4.066-7.517Z"
      />
    </svg>
  );
});

Phone.defaultProps = {
  color: 'currentColor',
};

Phone.displayName = 'Phone';

export default Phone;
